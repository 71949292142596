<template>
  <getecma-layout justify-content="initial" direction="row d-xs-flex-column">
    <getecma-container :class="classes" :justify-content="justifyContent" direction="column">
      <slot name="aside" />
    </getecma-container>
    <slot name="content" />
  </getecma-layout>
</template>

<script>
export default {
  name: 'GetecmaAsideContentLayout',
  props: {
    margins: {
      type: String,
      default: 'pe--lg',
    },
    widht100: {
      type: Boolean,
      default: false,
    },
    cols: {
      type: String,
      default: 'col-4',
    },
    justifyContent: {
      type: String,
      default: 'initial',
    },
  },
  computed: {
    classes() {
      if (this.widht100) {
        return [
          'w-100',
          'h-100',
          'col-xs-12',
          this.cols,
          this.margins,
        ];
      }
      return [
        'h-100',
        'col-xs-12',
        this.cols,
        this.margins,
      ];
    },
  },
};
</script>
